<!--=========================================================================================
  File Name: GameRoom.vue
  Description: Game Room Component
==========================================================================================-->
<template>
<div :class="{ 'locked': game.isLocked}" class="room-container" @mouseover="mouseIn" @mouseleave="mouseLeave">
    <b-overlay :show="show && !game.isLocked" rounded="sm" class="custom-overlay" variant="dark" v-bind:class="{hover_room: show && !game.isLocked }">
        <b-button class="play-game" @click="redirectGame(game.game, game.roomno)" v-show='show && !game.isLocked'>{{ $t('lobby.baccarat') }}</b-button>
        <div v-if="game.isLocked" class="locked-text">
            <h6>{{ $t('gameroom.closeTextHeader') }}</h6>
            <div>{{ $t('gameroom.closeTextTop') }}</div>
            <div>{{ $t('gameroom.closeTextBot') }}</div>
        </div>
        <div v-if="!game.isLocked" class="room-info">
            <div class="room-details">
                <div class="shoe-info" style="height: 21vh;display: block;overflow: hidden">
                    <b-row style="margin-left:0px;">
                        <!-- <b-col cols="5" class="grid-x-repeat-m">
                            <result-road :shoeResults="game.shoe" :heightShoe="resultRoadH" :widthShoe="resultRoadW" :gameType="game.type" />
                        </b-col> -->
                        <b-col style="padding-left: 0px !important;" v-if="game.shoe == '' " >
                            <div class="image-shuffle" >
                            <img class="img card-shuffle" src="/assets/images/closed-bg.png" />
                            <div class="bottom-text"><span>{{$t('lobby.cardShuffle') }}</span></div>
                            </div>
                        </b-col>
                        <b-col cols="12" class="rm-padding">
                            <b-row>
                                <b-col cols="12" class="grid-x grid-x-repeat-big-m rm-padding">
                                    <result-big-road :shoeResults="game.shoe" :heightShoe="resultBRoadH" :widthShoe="resultBRoadW" />
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" class="grid-x grid-x-repeat-big-eye-m rm-padding">
                                    <result-big-eye-road :shoeResults="game.shoe" />
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6" class="grid-x grid-x-repeat-small-m rm-padding">
                                    <result-small-road :shoeResults="game.shoe" />
                                </b-col>
                                <b-col cols="6" class="grid-x grid-x-repeat-roach-m rm-padding">
                                    <result-cockroach-road :shoeResults="game.shoe" />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
                <div class="details">
                    <div class="room-time">{{ roomCount + 1 }}</div>
                    <div class="room-round" v-bind:style="{width: roomRoundW + 'vw'}" v-if = "game.game == 'EVO' ">{{ getEvoRoomno(game.roomno) + ' : ' + getRoundNumber(game.roomorder) }}</div>
                    <div class="room-round" v-bind:style="{width: roomRoundW + 'vw'}"  v-if = "game.game != 'EVO' ">{{ game.roomno + ' : ' + getRoundNumber(game.roomorder) }}</div>
                    <div class="banker-player">
                        <result-count :shoe="game.shoe" :gameType="game.type" />
                    </div>
                    <div class="room-time-mobile"><timer :timeLeft="gameTimer" /></div>
                </div>
            </div>
            <div class="logo" :style="{ backgroundImage: `url(${$t('publicImgUrl') + $t('gameLogoURL.' + gameCode)})` }"></div>
        </div>
        <div class="overlay">
        </div>
    </b-overlay>
</div>
</template>

<script>
// import ResultRoad from '@/components/baccarat/roads/mobile/ResultRoad'
import ResultCount from '@/components/baccarat/roads/mobile/ResultCount.vue';
import ResultBigRoad from '@/components/baccarat/roads/mobile/ResultBigRoad'
import ResultBigEyeRoad from '@/components/baccarat/roads/mobile/ResultBigEyeRoad'
import ResultSmallRoad from '@/components/baccarat/roads/mobile/ResultSmallRoad'
import ResultCockroachRoad from '@/components/baccarat/roads/mobile/ResultCockroachRoad'
import Timer from '@/components/common/LobbyTimer'

export default {
    name: 'GameRoomMain',
    components: {
        // ResultRoad,
        ResultBigRoad,
        ResultBigEyeRoad,
        ResultSmallRoad,
        ResultCockroachRoad,
        ResultCount,
        Timer
    },
    created() {
        this.resizeRoundView();
    },
    data() {
        return {
            show: false,
            gameTimer: this.computeInitTimer(this.game.timer) || 0,
            roomRoundW: 0,
            // resultRoad: [],
            resultBigRoad: [],
            resultBigEyeRoad: [],
            resultCockroachRoad: [],
            resultSmallRoad: [],
            resultRoadH: "3.5vh",
            resultRoadW: "3.5vh",
            resultBRoadH: 1.6,
            resultBRoadW: 1.6
        }
    },
    props: {
        game: {
            required: true,
            type: Object
        },
        level: {
            required: true,
            type: Object,
            default: () => ({
                player_min: 0,
                player_max: 0
            })
        },
        lobbyImg: {
            required: true,
            type: String
        },
        selectedCols: {
            required: true,
            type: Number
        },
        gameCode: {
            required: false, type: String
        },
        roomCount: {
            required: false,
            type: Number
        },
    },
    watch: {
        game: {
            handler(newVal) {
                var vm = this;
                if (vm.gameTimer == 0){
                    let beforetime, currenttime, timediff, newtime;

                    beforetime = new Date(newVal.timerdate);
                    currenttime = new Date();

                    timediff = (currenttime.getTime() - beforetime.getTime()) / 1000;
                    newtime = newVal.timer - parseInt(timediff) ;
                    if (newtime < 0) {
                        newtime = 0;
                    } else if (newtime > newVal.timer) {
                        newtime = newVal.timer;
                    }
                    vm.gameTimer = newtime;
                }
            },
            deep: true
        },
        gameTimer: {
            handler(val) {
                if (val > 0) {
                    setTimeout(() => {
                        this.gameTimer--;
                    }, 1000);
                }
            },
            immediate: true,
            deep: true
        },
        selectedCols: {
            handler() {
                this.resizeRoundView();
            }
        },

    },
    methods: {
        getEvoRoomno(roomno) {

            try {

                const roomSplit = roomno.split('-') // split for SP-A

                if(roomSplit[0] === 'SP') return this.$t('lobby.gameType.SBAC') + '-' + roomSplit[1]
                else if(roomSplit[0] === 'BC') return this.$t('lobby.gameType.BAC') + '-' + roomSplit[1]
                else if(roomSplit[0] === 'LGHT') return this.$t('lobby.gameType.LIGHTNING')
                else if (roomSplit[0] === 'KOSB') return this.$t('lobby.gameType.KOREANBAC') + '-' + roomSplit[1]

            } catch(e) {
                console.log(e)
            }
        },
        isMobile() {
            if (/Android|android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        computeInitTimer(time) {
            let beforetime, currenttime, timediff, newtime = 0;

            beforetime = new Date(this.game.timerdate);
            currenttime = new Date();

            timediff = (currenttime.getTime() - beforetime.getTime()) / 1000;
            newtime = time - parseInt(timediff) ;
            if (newtime < 0) {
                newtime = 0;
            } else if (newtime > time) {
                newtime = time;
            }
            return newtime;
        },
        redirectGame(game, roomno) {
            this.show = !this.show

            this.$emit('pauseAudio');

            this.$store.commit('gameModule/SET_ROOM_DATA', {roomno, roomorder: '', state: 2, shoe: '', time: 0, result: null})
            
            if(this.game.game === "CMB" && this.game.type == 'BULL') {
                   this.$router.push(`/m/game/bull/${game}/${roomno}`);
            }else if (this.game.type == `DT`) {
               this.$router.push(`/m/game/dt/${game}/${roomno}`);
            } else {
              this.$router.push(`/m/game/${game}/${roomno}`);
            }
        },
        getRoundNumber(roomorder) {

            try {
                return roomorder.split('-')[1] || 0
            } catch (e) {
                return 0
            }
        },
        mouseIn() {
            this.show = true;
        },
        mouseLeave() {
            this.show = false;
        },
        drawGrids() {
            var lc = new Array(7).fill('').map(() => new Array(6).fill(''));
            return lc;
        },
        resizeRoundView() {
            if (this.selectedCols == 4) {
                this.roomRoundW = 3;
            }
            else if (this.selectedCols == 3) {
                this.roomRoundW = 8;
            } else {
                this.roomRoundW = 12;
            }
        },

    },
}
</script>

<style>
.bg-dark {
    background-color: rgb(0, 0, 0, 0.7) !important;
}

.play-game {
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 12;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    height: 2.5vw;
    top: 45%;
    width: 5.5vw;
    color: #ffcf39;
    border: 1px solid #ffcf39;
    background-color: rgb(0, 0, 0, 0.2) !important;
    font-size: 2vh;
}

.rs-ctnr-t {
    width: 100%;
    position: absolute;
    top: 0px;
}

.grids {
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
}

.div-left {
    float: left;
}

.bet-limit-div {
    margin-top: -5px;
}
</style><style>
@import '../../../../assets/scss/mobile/game_room_mobile.scss';
@import '../../../../assets/scss/mobile/spinning_bet_bg.scss';
</style>
