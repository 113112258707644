var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('betting-history',{ref:"modalName"}),_c('settings',{ref:"settingsModal",attrs:{"lobby":_vm.lobby}}),_c('div',{staticClass:"head row"},[_c('div',{staticClass:"balance-container col-sm"},[_c('div',{staticClass:"b-cont"},[_c('span',{staticClass:"balance-label"},[_vm._v(_vm._s(_vm.$t("lobby.balanceLabel")))]),_c('span',{staticClass:"balance"},[_vm._v(_vm._s(_vm._f("currency")(_vm.member.balance)))])])]),_c('div',{staticClass:"logo-container col-sm",style:({
        backgroundImage: ("url(" + (_vm.$t('publicImgUrl') + _vm.$t('gameLogoURL.' + _vm.gameCode)) + ")"),
      })}),_c('div',{staticClass:"user-container col-sm"},[_c('div',{staticClass:"right-col"},[_c('img',{staticClass:"img img-fluid top-icn-profile img-height-30 header-icn",attrs:{"src":"/assets/images/gameroom/Profile_icon.png"}}),_c('span',{staticClass:"user-info header-icn",attrs:{"header-icn":""}},[_vm._v(" "+_vm._s(_vm.member.username)+" ")]),_c('span',{staticClass:"history-info"},[_c('img',{staticClass:"img img-fluid top-icn-history img-height-30 cursor-pointer header-icn",attrs:{"src":"/assets/images/gameroom/btn_history.png"},on:{"click":function($event){return _vm.$refs.modalName.openModal()}}})]),_c('span',{staticClass:"setting-info"},[_c('img',{staticClass:"img img-fluid top-icn-history img-height-30 cursor-pointer",attrs:{"src":"/assets/images/gamelobby/settings.png"},on:{"click":function($event){return _vm.$refs.settingsModal.openModal()}}})]),_c('b-dropdown',{class:['language-selector']},[_c('template',{slot:"button-content"},[_c('img',{staticClass:"img-fluid flags-m flgUi",attrs:{"src":_vm.selectedLang.imgPath}})]),_vm._l((_vm.langs),function(lang){return _c('b-dropdown-item',{key:("Lang" + (lang.code)),attrs:{"value":lang.code},on:{"click":function($event){_vm.$i18n.locale = lang.code;
              _vm.selectLang(lang);}}},[_c('b-row',{staticStyle:{"border-top":"1px solid rgb(190, 174, 147)","padding-top":"8px"}},[_c('b-col',{attrs:{"md":"6"}},[_c('span',{staticClass:"gold-text"},[_vm._v(_vm._s(_vm.$t("lang." + lang.label)))])]),_c('b-col',{attrs:{"md":"6"}},[_c('img',{staticClass:"flags-m",attrs:{"src":lang.imgPath}})])],1)],1)})],2)],1)])]),_c('div',{staticClass:"separator"},[_c('div',{staticClass:"column-selector"},[_c('span',{staticClass:"four-column",on:{"click":function($event){return _vm.colsDiv(4)}}}),_c('span',{staticClass:"three-column",on:{"click":function($event){return _vm.colsDiv(3)}}}),_c('span',{staticClass:"two-column",on:{"click":function($event){return _vm.colsDiv(2)}}})])]),(_vm.lobby.selectedcategory != '')?_c('div',{staticStyle:{"margin":"0 15px"}},[_c('nav',[_c('ul',[_c('li',[_c('a',{staticClass:"category",attrs:{"href":"#"},on:{"click":function($event){return _vm.openCategory()}}},[_vm._v(" "+_vm._s(_vm.$t("lobby.gameCategory." + _vm.lobby.selectedcategory)))]),(_vm.isCategoryOpen)?_c('ul',{staticStyle:{"display":"inherit"}},_vm._l((_vm.gameCategory),function(category,index){return _c('div',{key:index,staticClass:"li-list",class:{
                'gm-active': _vm.lobby.selectedcategory == category,
              },staticStyle:{"cursor":"pointer","border-bottom":"#beae93 1px solid","padding-top":"1.2vh","padding-bottom":"1.2vh","position":"relative","z-index":"999"},on:{"click":function($event){return _vm.changeCategory(category)}}},[_c('span',{staticClass:"gold-text"},[_vm._v(" "+_vm._s(_vm.$t("lobby.gameCategory." + category)))])])}),0):_vm._e()])])])]):_vm._e(),_c('div',{staticClass:"lobby-list"},[(_vm.lobby.selectedcategory != '')?_c('div',{staticStyle:{"background":"linear-gradient(","border-left":"1px solid #beae93","border-right":"1px solid #beae93"},attrs:{"cols":"1"}}):_vm._e(),_c('div',{staticClass:"game-list",staticStyle:{"overflow-y":"scroll"}},[_c('div',{staticClass:"row"},_vm._l((_vm.gameRooms),function(room,idx){return _c('div',{key:idx,staticClass:"row col-lg-6 col-md-4 col-sm-4 lobby-row col-lobby"},[(
              _vm.lobby.selectedGameType != 'BULL' &&
              room.type != 'BULL' &&
              _vm.lobbyDisplay == 'MAIN'
            )?_c('game-room-main',{attrs:{"game":room,"lobbyImg":_vm.gameLobbyImg,"level":_vm.selectedLevelDetails(),"selectedCols":_vm.cols,"gameCode":_vm.gameCode,"roomCount":idx},on:{"pauseAudio":_vm.pauseAudio}}):_vm._e(),(
              _vm.lobby.selectedGameType != 'BULL' &&
              room.type != 'BULL' &&
              _vm.lobbyDisplay == 'NO_BIG_ROAD'
            )?_c('game-room-no-big-road',{attrs:{"game":room,"lobbyImg":_vm.gameLobbyImg,"level":_vm.selectedLevelDetails(),"selectedCols":_vm.cols,"gameCode":_vm.gameCode,"roomCount":idx},on:{"pauseAudio":_vm.pauseAudio}}):_vm._e(),(
              (_vm.lobby.selectedGameType == 'BULL' ||
                _vm.lobby.selectedGameType == '') &&
              room.type == 'BULL'
            )?_c('bull-game-room',{attrs:{"game":room,"lobbyImg":_vm.gameLobbyImg,"level":_vm.selectedLevelDetails(),"selectedCols":_vm.cols,"roomCount":idx},on:{"pauseAudio":_vm.pauseAudio}}):_vm._e()],1)}),0)])]),_c('div',{staticClass:"bottom-fxd"},[_c('ul',{staticClass:"filter-lu",staticStyle:{"margin-left":"1vw","text-align":"center"}},_vm._l((_vm.gameTypes),function(type,index){return _c('li',{key:index,staticClass:"filter-li",class:{ activefilter: _vm.lobby.selectedGameType == type || (_vm.lobby.selectedGameType  == '' && type == 'all')  },staticStyle:{"border-right":"1px solid #beae93"},style:({ width: _vm.filterW + 'vw' }),on:{"click":function($event){return _vm.changeGameType(type)}}},[_c('span',{staticClass:"gold-text"},[_vm._v(_vm._s(_vm.$t("lobby.gameType." + type)))])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }