<!--=========================================================================================
  File Name: GameLobby.vue
  Description: Game Lobby Page
==========================================================================================-->

<template>
  <div class="content">
    <betting-history ref="modalName" />
    <settings ref="settingsModal" :lobby="lobby" />
    <div class="head row">
      <div class="balance-container col-sm">
        <div class="b-cont">
          <span class="balance-label">{{ $t("lobby.balanceLabel") }}</span>
          <span class="balance">{{ member.balance | currency }}</span>
        </div>
      </div>
      <div
        class="logo-container col-sm"
        :style="{
          backgroundImage: `url(${
            $t('publicImgUrl') + $t('gameLogoURL.' + gameCode)
          })`,
        }"
      ></div>
      <div class="user-container col-sm">
        <div class="right-col">
          <img
            src="/assets/images/gameroom/Profile_icon.png"
            class="img img-fluid top-icn-profile img-height-30 header-icn"
          />
          <span class="user-info header-icn" header-icn>
            {{ member.username }}
          </span>
          <span class="history-info">
            <img
              src="/assets/images/gameroom/btn_history.png"
              class="img img-fluid top-icn-history img-height-30 cursor-pointer header-icn"
              @click="$refs.modalName.openModal()"
            />
          </span>
          <span class="setting-info">
            <img
              src="/assets/images/gamelobby/settings.png"
              class="img img-fluid top-icn-history img-height-30 cursor-pointer"
              @click="$refs.settingsModal.openModal()"
            />
          </span>
          <b-dropdown :class="['language-selector']">
            <template slot="button-content">
              <img
                class="img-fluid flags-m flgUi"
                v-bind:src="selectedLang.imgPath"
              />
            </template>
            <b-dropdown-item
              v-for="lang in langs"
              :key="`Lang${lang.code}`"
              :value="lang.code"
              @click="
                $i18n.locale = lang.code;
                selectLang(lang);
              "
            >
              <b-row
                style="
                  border-top: 1px solid rgb(190, 174, 147);
                  padding-top: 8px;
                "
              >
                <b-col md="6">
                  <span class="gold-text">{{ $t("lang." + lang.label) }}</span>
                </b-col>
                <b-col md="6">
                  <img v-bind:src="lang.imgPath" class="flags-m" />
                </b-col>
              </b-row>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="separator">
      <div class="column-selector">
        <span class="four-column" @click="colsDiv(4)"></span>
        <span class="three-column" @click="colsDiv(3)"></span>
        <span class="two-column" @click="colsDiv(2)"></span>
      </div>
    </div>
    <div style="margin: 0 15px" v-if="lobby.selectedcategory != ''">
      <nav>
        <ul>
          <li>
            <a href="#" class="category" @click="openCategory()">
              {{ $t("lobby.gameCategory." + lobby.selectedcategory) }}</a
            >
            <ul style="display: inherit" v-if="isCategoryOpen">
              <div
                class="li-list"
                v-bind:class="{
                  'gm-active': lobby.selectedcategory == category,
                }"
                v-for="(category, index) in gameCategory"
                :key="index"
                @click="changeCategory(category)"
                style="
                  cursor: pointer;
                  border-bottom: #beae93 1px solid;
                  padding-top: 1.2vh;
                  padding-bottom: 1.2vh;
                  position: relative;
                  z-index: 999;
                "
              >
                <span class="gold-text">
                  {{ $t("lobby.gameCategory." + category) }}</span
                >
              </div>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div class="lobby-list">
      <div
        v-if="lobby.selectedcategory != ''"
        cols="1"
        style="
          background: linear-gradient(
            358deg,
            rgb(22, 22, 22) 0%,
            rgb(35, 35, 35) 35%,
            rgb(48, 48, 48) 100%
          );
          border-left: 1px solid #beae93;
          border-right: 1px solid #beae93;
        "
      ></div>
      <div class="game-list" style="overflow-y: scroll">
        <div class="row">
          <div
            v-for="(room, idx) in gameRooms"
            :key="idx"
            class="row col-lg-6 col-md-4 col-sm-4 lobby-row col-lobby"
          >
            <game-room-main
              :game="room"
              :lobbyImg="gameLobbyImg"
              :level="selectedLevelDetails()"
              :selectedCols="cols"
              @pauseAudio="pauseAudio"
              v-if="
                lobby.selectedGameType != 'BULL' &&
                room.type != 'BULL' &&
                lobbyDisplay == 'MAIN'
              "
              :gameCode="gameCode"
              :roomCount="idx"
            />
            <game-room-no-big-road
              v-if="
                lobby.selectedGameType != 'BULL' &&
                room.type != 'BULL' &&
                lobbyDisplay == 'NO_BIG_ROAD'
              "
              :game="room"
              :lobbyImg="gameLobbyImg"
              :level="selectedLevelDetails()"
              :selectedCols="cols"
              @pauseAudio="pauseAudio"
              :gameCode="gameCode"
              :roomCount="idx"
            />
            <bull-game-room
              :game="room"
              :lobbyImg="gameLobbyImg"
              :level="selectedLevelDetails()"
              :selectedCols="cols"
              @pauseAudio="pauseAudio"
              v-if="
                (lobby.selectedGameType == 'BULL' ||
                  lobby.selectedGameType == '') &&
                room.type == 'BULL'
              "
              :roomCount="idx"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-fxd">
      <ul class="filter-lu" style="margin-left: 1vw; text-align: center">
        <li
          :class="{ activefilter: lobby.selectedGameType == type || (lobby.selectedGameType  == '' && type == 'all')  }"
          class="filter-li"
          v-for="(type, index) in gameTypes"
          :key="index"
          @click="changeGameType(type)"
          :style="{ width: filterW + 'vw' }"
          style="border-right: 1px solid #beae93"
        >
          <span class="gold-text">{{ $t("lobby.gameType." + type) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import GameRoomMain from "@/components/baccarat/lobby/mobile/GameRoom";
import GameRoomNoBigRoad from "@/components/baccarat/lobby/mobile/GameSwitchRoom";
import BullGameRoom from "@/components/bull/lobby/BullGameRoom";
import BettingHistory from "@/views/common/pc/BettingHistory";
import Settings from "@/components/baccarat/game/mobile/Settings";

export default {
  name: "GameLobby",
  components: {
    GameRoomMain,
    GameRoomNoBigRoad,
    BettingHistory,
    BullGameRoom,
    Settings,
  },
  async beforeCreate() {
    await this.$store.dispatch("gameModule/checkIfLoggedIn");

    this.bgAudio.loop = true;
    if (
      localStorage.getItem("muteAudio") == 0 &&
      localStorage.getItem("bgmPlaying") == "false"
    ) {
      this.bgAudio.play();
      localStorage.setItem("bgmPlaying", true);
    }
  },
  mounted() {
    this.lobby.selectedcategory = this.gameCategory[0] || "";
  },
  data() {
    return {
      isDisplayModal: "",
      cols: 3,
      lobby: {
        selectedGameType: "",
        selectedlevel: 1,
        selectedcategory: "",
      },
      langs: [
        {
          code: "ko-KR",
          label: "korean",
          imgPath: "/assets/images/gamelobby/flags/korea.png",
        },
        {
          code: "en-US",
          label: "english",
          imgPath: "/assets/images/gamelobby/flags/usa.png",
        },
        {
          code: "ch-CH",
          label: "chinese",
          imgPath: "/assets/images/gamelobby/flags/china.png",
        },
      ],
      selectedLang: {},
      gameLogo:
        "http://game.bet4api.com/assets/images/logos-header/88_logo.png",
      gameLobbyImg: "http://game.bet4api.com/assets/images/logos/op_logo.png",
      bgAudio: new Audio("/assets/sounds/ko/bgm1.mp3"),
      roomList: [],
      isCategoryOpen: false,
    };
  },
  computed: {
    gameRooms() {
      if (this.lobby.selectedGameType === "")
        return this.$store.state.gameModule.game.rooms.filter(
          (r) => r.category === this.lobby.selectedcategory
        );
      else
        return this.$store.state.gameModule.game.rooms.filter(
          (r) =>
            r.category === this.lobby.selectedcategory &&
            r.type === this.lobby.selectedGameType
        );
    },
    gameCategory() {
      return window._.uniq(
        window._.map(this.$store.state.gameModule.game.rooms, "category")
      );
    },
    gameTypes() {
      var r = window._.uniq(
        window._.map(this.$store.state.gameModule.game.rooms, "type")
      );
      r.unshift("all");
      return r;
    },
    filterW() {
      return 100 / this.gameTypes.length;
    },
    member() {
      return this.$store.state.gameModule.member;
    },
    levels() {
      return this.$store.state.gameModule.levels;
    },
    gameCode() {
      return this.$store.state.gameModule.game.code;
    },
    lobbyDisplay() {
      return this.$store.state.gameModule.settings.lobbyRoomDisplay;
    },
  },
  methods: {
    changeCategory(category) {
      this.isCategoryOpen = !this.isCategoryOpen;
      this.lobby.selectedcategory = category;
    },
    openCategory() {
      this.isCategoryOpen = !this.isCategoryOpen;
    },
    changeGameType(id = "") {
      if (id == "all") {
        id = "";
      }
      this.lobby.selectedGameType = id;
    },
    changeLevel(id) {
      this.lobby.selectedlevel = id;
      this.$store.commit("gameModule/SET_CURRENT_LEVEL", id);
    },
    filteredGamesByType() {
      if (this.lobby.selectedGameType != "") {
        return this.games.filter(
          (game) => game.gameType == this.lobby.selectedGameType
        );
      } else {
        return this.games;
      }
    },
    selectedLevelDetails() {
      return this.levels.filter(
        (level) => level.level == this.lobby.selectedlevel
      )[0];
    },
    colsDiv(v) {
      this.cols = v;
    },
    selectLang(lang) {
      this.selectedLang = lang;
      localStorage.setItem("langLabel", lang.label);
      localStorage.setItem("langimg", lang.imgPath);
      localStorage.setItem("langcode", lang.code);
    },
    pauseAudio() {
      this.bgAudio.pause();
      localStorage.setItem("bgmPlaying", false);
    },
  },
  created() {
    this.selectedLang.label = localStorage.getItem("langLabel");
    this.selectedLang.imgPath = localStorage.getItem("langimg");
    this.selectedLang.code = localStorage.getItem("langcode");

    if (this.selectedLang.code == null) {
      this.selectedLang = this.langs[0];
      localStorage.setItem("langLabel", this.selectedLang.label);
      localStorage.setItem("langimg", this.selectedLang.imgPath);
      localStorage.setItem("langcode", this.selectedLang.code);
    } else {
      this.selectedLang = {
        label: this.selectedLang.label,
        imgPath: this.selectedLang.imgPath,
        code: this.selectedLang.code,
      };
    }

    this.$i18n.locale = this.selectedLang.code;

    this.bgAudio.loop = true;
    if (
      localStorage.getItem("muteAudio") == 0 &&
      localStorage.getItem("bgmPlaying") == "false"
    ) {
      this.bgAudio.play();
      localStorage.setItem("bgmPlaying", true);
    }
  },
};
</script>

<style scoped>
body {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #000;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.shoe-info {
  position: absolute;
}

.flags {
  height: auto;
  width: 1vw;
  right: 0px;
}

.twoCols {
  width: 46%;
  margin-right: 2%;
  margin-left: 2%;
}

.threeCols {
  width: 31%;
  margin-left: 1.2%;
  margin-right: 1%;
}

.fourCols {
  width: 24%;
  margin-right: 0.5%;
  margin-left: 0.5%;
}

.b-cont {
  height: 100%;
  display: table;
  margin-left: 5vw;
}

.balance-label,
.balance {
  display: table-cell;
  vertical-align: middle;
}

.user-info {
  font-weight: bold;
}

.history-info {
  cursor: pointer;
  margin: 0 1vw;
}

.lobby-row {
  padding: 0 10px 15px 10px;
}

nav {
  margin: 10px 0;
  background-color: transparent;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul li {
  display: inline-block;
  width: 100%;
  text-align: center;
}

nav a {
  display: block;
  padding: 0 10px;
  color: #fff;
  font-size: 20px;
  line-height: 40px;
  text-decoration: none;
}

nav a:hover {
  background-color: #000000;
}

nav ul ul {
  display: none;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
}
/*
nav ul li:hover > ul {
  display: inherit;
} */

nav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
}

nav ul ul ul li {
  position: relative;
  top: -60px;
  left: 170px;
}

.li-list {
  background: linear-gradient(
    358deg,
    rgb(22, 22, 22) 0%,
    rgb(35, 35, 35) 35%,
    rgb(48, 48, 48) 100%
  );
  border-left: 1px solid rgb(190, 174, 147);
  border-right: 1px solid rgb(190, 174, 147);
}

.gm-active {
  background: black;
}

.category {
  color: #b38728;
  background: url(/assets/images/gamelobby/game-type-inactive.png) no-repeat
    center;
  background-size: 100% 100%;
  border: 2px solid #beae93;
  border-right: 2px solid #beae93;
  border-bottom: 2px solid #beae93;
  height: 40px;
}

a:hover {
  color: #b38728;
  text-decoration: none;
}
.flgUi {
  left: 0.2em !important;
}

.filter-lu {
  list-style-type: none;
  padding-left: 0px;
  width: 100vw;
}

.filter-li {
  float: left;
}

.activefilter {
  background-color: #000;
}

.lobby-list {
  padding-bottom: 5vh;
  padding-top: 1vh;
  border-bottom: none;
  border-left: none;
  border-right: none;
  height: 100vh;
  overflow: scroll;
}
.header-icn {
  margin-right: 1.2vw;
}
.bottom-fxd {
  position: fixed;
  bottom: 0;
  background: linear-gradient(0deg, #161616 0%, #232323 35%, #303030 100%);
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-top: 1px solid #beae93;
  background-color: #2c2c2c !important;
  z-index: 12;
}
</style><style>
@import "../../../assets/scss/mobile/game_lobby_mobile.scss";
</style>
