<!--=========================================================================================
  File Name: Settings.vue
  Description: Settings
==========================================================================================-->
<template>
<div>
    <transition name="fade">
        <div class="modal" v-if="show" >
            <div class="modal__backdrop" @click="closeModal()" />
            <div class="modal__dialog" style="width:100% !important;">
                <div class="modal_main_body">
                    <div class="modal__header">
                        <div class="header-container">
                            <!-- <span class="header-title gold-text">{{ $t('betHistory.betHistoryTitle') }}</span> -->
                        </div>
                        <button type="button" class="modal__close" @click="closeModal()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">

                      <div class="level-div">
                        <div class="user-level-container">
                          <b-row class="justify-content-md-center">
                            <b-col
                              class="level-detail level-detail-row"
                              v-for="(userLevel, idx) in levels"
                              :class="{ selected: lobby.selectedlevel == userLevel.level }"
                              :key="idx"

                              @click="changeLevel(userLevel.level)" style="margin-top:1em"
                            >
                              <div>
                                <span class="min-bet-label">{{
                                  $t("level." + userLevel.level)
                                }}</span>
                              </div>
                              <div>
                                <span class="min-bet-label">{{ $t("lobby.playerBetLimit") }}</span>
                                <span class="min-bet-val"
                                  >{{ userLevel.player_min | currency("", ",", "0") }} ~
                                  {{ userLevel.player_max | currency("", ",", "0") }}</span
                                >
                              </div>
                              <div>
                                <span class="min-bet-label">{{ $t("lobby.bankerBetLimit") }}</span>
                                <span class="min-bet-val"
                                  >{{ userLevel.banker_min | currency("", ",", "0") }} ~
                                  {{ userLevel.banker_max | currency("", ",", "0") }}</span
                                >
                              </div>
                              <div>
                                <span class="max-bet-label">{{ $t("lobby.tieBetLimit") }}</span>
                                <span class="max-bet-val"
                                  >{{ userLevel.tie_min | currency("", ",", "0") }} ~
                                  {{ userLevel.tie_max | currency("", ",", "0") }}</span
                                >
                              </div>
                              <div>
                                <span class="max-bet-label">{{ $t("lobby.pairBetLimit") }}</span>
                                <span class="max-bet-val"
                                  >{{ userLevel.pair_min | currency("", ",", "0") }} ~
                                  {{ userLevel.pair_max | currency("", ",", "0") }}</span
                                >
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <br>
                      <button type="button" @click='saveLevel' class="btn btn-warning" style="background-color: #ffc107;">Save</button>

                    </div>
                </div>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
export default {
    name: 'Settings',
    components: {
    },
    mounted() {

    },
    computed: {
      levels() {
        return this.$store.state.gameModule.levels;
      },
    },
    data() {
        return {
          show: false,
        }
    },
    props: {
        lobby: {required: true, type: Object},

    },

    methods: {
        closeModal() {
            this.show = false;
        },
        openModal() {
            this.show = true;
        },
        changeLevel(id) {
          this.lobby.selectedlevel = id;
          this.$store.commit("gameModule/SET_CURRENT_LEVEL", id);
        },
        saveLevel() {
          this.$store.commit("gameModule/SET_CURRENT_LEVEL", this.lobby.selectedlevel);
          this.closeModal()
        }
    },
}
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    overflow-x: hidden;
    overflow-y: auto;
    color: black;
    display: block;

    &__backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }

    &__dialog {
        position: relative;
        width: 85%;
        height: 85%;
        background-color: transparent;
        border-radius: 5px;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        z-index: 99999;

        @media screen and (max-width: 992px) {
            width: 90%;
        }
    }

    &__close {
        width: 30px;
        height: 30px;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px 20px 10px;
    }

    &__body {
        padding: 10px 20px 10px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin: 0 2% 2% 2%;
        color: white;
        background-color: black;
        height: 85%;
        width: 96%;
        top: 12%;
        position: absolute;
        border-radius: 15px;
    }

    &__footer {
        padding: 10px 20px 20px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.modal_main_body {
    height: 100%;
    width: 100%;
    background: rgba(63, 59, 58, 0.95);
    border-radius: 10px;
}

.header-title {
    font-size: 4.5vh;
    font-weight: bold;
    top: 1.5vh;
}

/* Reset Select */
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #282725;
    background-image: none;
    color: #917e63;
}

/* Remove IE arrow */
select::-ms-expand {
    display: none;
}

/* Custom Select */
.select {
    position: relative;
    display: flex;
    width: 11em;
    height: 2.1em;
    background: #282725;
    overflow: hidden;
    border-radius: 0.25em;
    font-size: 1.7vh;
}

select {
    flex: 1;
    padding: 0 .5em;
    color: #96816a;
    cursor: pointer;
}

/* Arrow */
.select::after {
    // content: '\25BC';
    content: "▼";
    position: absolute;
    top: 0.29em;
    right: 5px;
    padding: 0px 4px;
    background: #433f3e;
    cursor: pointer;
    pointer-events: none;
    transition: 0.25s all ease;
    border: 1px #433f3e solid;
    border-radius: 2px;

}

/* Transition */
.select:hover::after {
    color: #d4ae81;
}

.history-table,
.table-con {
    width: 100%;
}

.history-table th {
    background-color: #2d2d2d;
    height: 4vh;
    font-size: 1.7vh;
    vertical-align: middle;
    text-align: center;
    border: 1px #282828 solid
}

.history-table td {
    // background-color: #2d2d2d;
    height: 4vh;
    font-size: 1.7vh;
    vertical-align: middle;
    text-align: center;
    border: 1px #282828 solid
}

.btn-4,
.btn-5 {
    height: 2.1em;
    width: 7.3em;
    background: rgb(66, 60, 60);
    background: linear-gradient(180deg, rgba(66, 60, 60, 1) 22%, rgba(48, 44, 43, 1) 75%);
    border: 1px #302c2b solid;
    border-radius: 3px;
    display: inline-block;
    margin-left: 15px;
    font-size: 1.7vh;
    color: white;
}

.btn-4:active,
.btn-4:focus,
.btn-5:active,
.btn-5:focus {
    height: 2.1em;
    width: 7.3em;
    background: rgb(229, 192, 149);
    background: linear-gradient(180deg, rgba(229, 192, 149, 1) 22%, rgba(195, 152, 104, 1) 75%);
    border: 1px #79624b solid;
    border-radius: 5px;
    display: inline-block;
    margin-left: 15px;
    font-size: 1.7vh;
}

.bottom-space {
    padding-bottom: 13px;
}

.cont-one {
    display: inline-block;
    margin-left: 15px;
    font-size: 1.7vh;
}

.cont-two {
    width: auto;
    position: absolute;
    right: 0;
}

.table-con {
    padding: 11px;
    border: 2px #272624 solid;
    margin: 8px;
    height: 100%;
}

.header-container {
    width: 100%;
    text-align: center;
}

.page-contner {
    padding-top: 20px;
    width: 100%;
}

.result-text-label {
    display: block;
}

.total-value {
    font-weight: 700;
}

.label-banker-result,
.label-banker-pair-result,
.banker,
.banker_pair,
.label-dragon-result {
    color: #dc3545;
}

.label-player-result,
.label-player-pair-result,
.player,
.player_pair,
.label-tiger-result {
    color: #007bff;
}

.label-tie-result,
.tie {
    color: #01ce00;
}

/* bull */

.show-bull-history {
    cursor: pointer;
}

.history-loading {
    background-image: url('/assets/images/game/history/loading.gif');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 3vw 3vw;
}

.history-loading td {
    height: 10vh;
}

.card-image {
    width: 3vw !important;
    height: 4.5vw !important;
    display: inline-block;
    background-size: cover;
}

.banker-text-rest {
    font-weight: 900;
    background: -webkit-linear-gradient(#dea0a0, #f00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.player-text-rest {
    font-weight: 900;
    background: -webkit-linear-gradient(#a5b2f1, #007eff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mrl-no {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.card-sum {
    position: relative;
    padding-left: 50%;
    font-size: 35px;
    font-weight: bold;
}

@media (min-width: 1024px) {

    .modal-lg,
    .modal-xl {
        max-width: 900px;
    }
}
</style>
